import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {PushNotificationsService} from '../../services/mobile-services/push-notifications.service';
import {ScreenOrientation} from '@capacitor/screen-orientation';
import {Capacitor} from '@capacitor/core';
import {BranchService} from '../../services/branch.service';
import {HistoryService} from '../../services/mobile-services/history.service';
import {App} from '@capacitor/app';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationComponent} from '../modal/confirmation/confirmation.component';

import {StatusBar, Style} from '@capacitor/status-bar';
import {SplashScreen} from '@capacitor/splash-screen';
import {Device} from '@capacitor/device';
import {last} from 'rxjs/operators';
import {SafeArea} from 'capacitor-plugin-safe-area';
import {NavigationBar} from '@mauricewegner/capacitor-navigation-bar';
import {CapacitorUpdater} from '@capgo/capacitor-updater';
import {Dialog} from '@capacitor/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  providers: [
    PushNotificationsService,
    HistoryService
  ]
})

export class RootComponent implements OnInit, OnDestroy {
  environment = environment;
  sides: Array<string> = ['left', 'right', 'bottom', 'top'];
  constructor(private pushNotificationsService: PushNotificationsService,
              private branchService: BranchService,
              private historyService: HistoryService,
              private modalService: NgbModal) {
  }

  ngOnInit() {
    if (environment.mobile && Capacitor.getPlatform() !== 'web') {
      this.instanciateMobileServices();
    } else {
      this.sides.forEach(side =>  {
        document.documentElement.style.setProperty(
            `--safe-area-${side}`,
            `0px`,
        );
      });
    }
  }

  instanciateMobileServices() {
    if (Capacitor.getPlatform() === "ios") {
      SplashScreen.show({
        showDuration: 3500,
        autoHide: true
      });
    }
    StatusBar.setBackgroundColor({color: '#00000000'})
    NavigationBar.setTransparency({ isTransparent: true });
    CapacitorUpdater.addListener('appReady', async () => {
      if (this.environment.production) {
        const deviceId = await CapacitorUpdater.setChannel({channel: 'production'});
      } else {
        const deviceId = await CapacitorUpdater.setChannel({channel: 'dev'});
      }
    });

    CapacitorUpdater.notifyAppReady();

    CapacitorUpdater.addListener('updateAvailable', async (res) => {
      try {
        await Dialog.alert({
          title: 'Update Available',
          message: `Version ${res.bundle.version} is available. The app will update now`,
        })
        CapacitorUpdater.set(res.bundle)
      } catch (error) {
        console.log(error);
      }
    });

    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      for (const [key, value] of Object.entries(insets)) {
        document.documentElement.style.setProperty(
            `--safe-area-${key}`,
            `${value}px`,
        );
      }
    });
    SafeArea.addListener('safeAreaChanged', data => {
      const { insets } = data;
      for (const [key, value] of Object.entries(insets)) {
        document.documentElement.style.setProperty(
            `--safe-area-${key}`,
            `${value}px`,
        );
      }
    });

    const screenOrientation = Capacitor.isPluginAvailable('ScreenOrientation');
    if(screenOrientation) {
      ScreenOrientation.lock({orientation: "portrait"}).then(() => {
        console.log('ScreenOrientation locked in portrait');
      }).catch(() =>  {console.log('ScreenOrientation lock failed');})
    }
    ScreenOrientation.addListener("screenOrientationChange", () => {
      SafeArea.getSafeAreaInsets().then(({insets}) => {
        for (const [key, value] of Object.entries(insets)) {
          document.documentElement.style.setProperty(
            `--safe-area-${key}`,
            `${value}px`,
          );
        }
      });
    });

    this.historyService.initHistory();
    this.branchService.initializeApp();
    App.addListener('resume', () => {
      StatusBar.setBackgroundColor({color: '#00000000'})
      NavigationBar.setTransparency({ isTransparent: true });
      this.branchService.initializeApp()
    });
    window.addEventListener('customBackButton', () => {
      ScreenOrientation.orientation().then(result => {
        if (result.type !== 'landscape-primary' && result.type !== 'landscape-secondary') {
          this.checkUrlHistory();
        }
      });
    });
    App.addListener('backButton', () => {
      ScreenOrientation.orientation().then(result => {
        if (result.type !== 'landscape-primary' && result.type !== 'landscape-secondary') {
          this.checkUrlHistory();
        }
      });
    });
  }

  checkUrlHistory() {
    const cloneHistory = [...this.historyService.history];
    const lastUrl = cloneHistory.pop();
    if (lastUrl === '/dashboard' || lastUrl === '/' || lastUrl === '/home' || lastUrl === undefined || lastUrl === 'undefined') {
      const modalRef = this.modalService.open(ConfirmationComponent);
      modalRef.componentInstance.title = 'Do you want to quit IMCAS ACADEMY ?';
      modalRef.result.then(() => {
        App.exitApp();
      }).catch(() => {
      });
    } else {
      this.historyService.navigateBack();
    }
  }

  ngOnDestroy() {
    SafeArea.removeAllListeners();
    ScreenOrientation.removeAllListeners();
  }
}

